import * as styles from './demo.module.scss';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import { Script, graphql } from 'gatsby';

import Callout from '../components/v2/callout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import SEO from '../components/seo';
import Section from '../components/section';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

const DemoPage = ({ data }) => {
  const headshotImages = data.headshotImages.edges;

  const initHubspotForm = () => {
    if ('hbspt' in window) {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '8853169',
        formId: '73d00ee7-8100-4cf0-b8fc-d2f7498cc0f5',
        target: '#hs-inquiry-form',
      });
    } else {
      setTimeout(initHubspotForm, 500);
    }
  };

  useEffect(() => {
    initHubspotForm();
  }, []);

  return (
    <>
      <SEO
        title="Request a Demo"
        description="Discover how Interact's innovative quiz platform can revolutionize your lead generation and boost sales. Schedule a personalized demo to unlock the power of interactive marketing for your business."
      />
      <Header />
      <main className={styles.demo}>
        <Section theme="white" style={{ marginTop: `4.5rem` }}>
          <div className="row justify-content-between align-items-start">
            <div className="col-12 col-lg-5 text-md-center">
              <h1 className="heading-xl color-black">Schedule your demo</h1>
              <h2 className="heading-sm color-muted-foreground" style={{ marginBottom: '2rem' }}>
                Let's dive deep into your quiz strategy, from concept to execution. We'll cover
                everything from crafting engaging questions to seamless website integration and
                email automation.
              </h2>
              <p className="heading-sm color-black">
                <strong>What to expect:</strong>
              </p>
              <ul className={styles.checklist} style={{ alignItems: 'flex-start' }}>
                <li>
                  <span className={styles.check}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <p className="text-body color-black">
                    Specific recommendations on the best quiz idea for your business
                  </p>
                </li>
                <li>
                  <span className={styles.check}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <p className="text-body color-black">
                    Exact instructions on how to integrate with your email marketing platform to
                    generate and segment leads
                  </p>
                </li>
                <li>
                  <span className={styles.check}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <p className="text-body color-black">
                    Experience-driven guidance on how to implement your quiz on your website and
                    social media
                  </p>
                </li>
                <li>
                  <span className={styles.check}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <p className="text-body color-black">
                    The answers to any other questions you may have
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-6 col-xl-5">
              <div id="hs-inquiry-form" className={styles.form}></div>
            </div>
          </div>
        </Section>
        <Section theme="white">
          <div className="row">
            <div className="col-12 text-center">
              <p className="heading-lg color-black" style={{ marginBottom: '1rem' }}>
                Average conversion rates for Interact quizzes:
              </p>
            </div>
          </div>
          <div
            className={`row no-gutters align-items-center justify-content-center ${styles.metrics}`}>
            <div className="col-md-4">
              <p className={styles.number}>41%</p>
              <p className={styles.label}>of people who start a quiz become a lead</p>
            </div>
            <div className="col-md-4">
              <p className={styles.number}>68%</p>
              <p className={styles.label}>of people who start a quiz complete it</p>
            </div>
            <div className="col-md-4">
              <p className={styles.number}>3.4x</p>
              <p className={styles.label}>average increase in order value for e-commerce</p>
            </div>
          </div>
        </Section>
        <Section theme="white" style={{ paddingBottom: '10rem' }}>
          <div className="row">
            <div className="col-12 text-center">
              <p className="heading-lg color-black" style={{ marginBottom: '1rem' }}>
                Interact quizzes drive results across diverse industries:
              </p>
            </div>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-4 col-xl-3">
              <ul className={styles.checklist}>
                <li>
                  <span className={styles.check}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <p className="text-body color-black">Coaching and Consulting</p>
                </li>
                <li>
                  <span className={styles.check}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <p className="text-body color-black">Education</p>
                </li>
                <li>
                  <span className={styles.check}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <p className="text-body color-black">Health and Fitness</p>
                </li>
                <li>
                  <span className={styles.check}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <p className="text-body color-black">Marketing Agencies</p>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-xl-3">
              <ul className={styles.checklist}>
                <li>
                  <span className={styles.check}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <p className="text-body color-black">E-commerce</p>
                </li>
                <li>
                  <span className={styles.check}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <p className="text-body color-black">Real Estate</p>
                </li>
                <li>
                  <span className={styles.check}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <p className="text-body color-black">Travel and Tourism</p>
                </li>
                <li>
                  <span className={styles.check}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <p className="text-body color-black">Beauty and Skincare</p>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-xl-3">
              <ul className={styles.checklist}>
                <li>
                  <span className={styles.check}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <p className="text-body color-black">Service Providers</p>
                </li>
                <li>
                  <span className={styles.check}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <p className="text-body color-black">Non-profits</p>
                </li>
                <li>
                  <span className={styles.check}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <p className="text-body color-black">Finance</p>
                </li>
                <li>
                  <span className={styles.check}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <p className="text-body color-black">Event Planning</p>
                </li>
              </ul>
            </div>
          </div>
        </Section>
        <Section theme="grey">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-8 text-center">
              <h3 className="heading-xl color-black">Meet the quiz experts.</h3>
            </div>
            <div className="col-12 col-lg-6 text-center">
              <p className="heading-sm color-muted-foreground" style={{ margin: '0' }}>
                Our team has been formulating high converting quizzes for over 10 years.
              </p>
            </div>
          </div>
          <div className="row" style={{ marginTop: '3rem' }}>
            <div className="col-lg-4 col-md-6 text-center" style={{ marginBottom: `1.5rem` }}>
              <div className={styles.card}>
                <div className={styles.img}>
                  <GatsbyImage image={getImage(headshotImages[1].node)} alt="Damaris Pacheco" />
                </div>
                <div className={styles.content}>
                  <p className="heading-sm color-black">
                    <strong>Damaris Pacheco</strong>
                  </p>
                  <p className="text-body color-muted-foreground" style={{ fontSize: '14px' }}>
                    Customer success manager at Interact, Damaris assists customers day in and day
                    out with setting up the best possible quizzes and she has a wealth of knowledge
                    to share.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 text-center" style={{ marginBottom: `1.5rem` }}>
              <div className={styles.card}>
                <div className={styles.img}>
                  <GatsbyImage image={getImage(headshotImages[8].node)} alt="Josh Haynam" />
                </div>
                <div className={styles.content}>
                  <p className="heading-sm color-black">
                    <strong>Josh Haynam</strong>
                  </p>
                  <p className="text-body color-muted-foreground" style={{ fontSize: '14px' }}>
                    Co-founder, CEO at Interact. Josh has been working with clients for over 10
                    years on quiz development and can pull in experience from just about any
                    use-case when helping you set up your best possible quiz.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 text-center" style={{ marginBottom: `1.5rem` }}>
              <div className={styles.card}>
                <div className={styles.img}>
                  <GatsbyImage image={getImage(headshotImages[12].node)} alt="Mia Fitzpatrick" />
                </div>
                <div className={styles.content}>
                  <p className="heading-sm color-black">
                    <strong>Mia Fitzpatrick</strong>
                  </p>
                  <p className="text-body color-muted-foreground" style={{ fontSize: '14px' }}>
                    As a former teacher, Mia is excellent as guiding customers through the
                    onboarding process with ease, and breaking down the complex process of quizzes
                    into simple steps.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Callout />
      </main>
      <Footer />
      <Script src="//js.hsforms.net/forms/embed/v2.js" />
    </>
  );
};

export default DemoPage;

export const query = graphql`
  query {
    headshotImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "about/headshots" }
      }
      sort: { fields: relativePath }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 96, quality: 100)
          }
        }
      }
    }
  }
`;
